<template>
  <div id="payment-method-form-container">
    <div v-if="addingPaymentMethod" class="my-5 py-5">
      <ct-centered-spinner>
        Adding new payment...
      </ct-centered-spinner>
    </div>
    <div v-if="isShowTypeOptions" class="choose-payment-header">
      <header>Choose a Payment Method</header>
      <div class="button-group">
        <b-button
          variant="outline-primary"
          class="mr-2"
          aria-label="credit or debit card button"
          @click="setSelectedPayableType('card')"
        >
          <b-img :src="`/images/creditCards/default.svg`" class="mr-2" />
          <span>
            Credit/Debit Card
          </span>
        </b-button>
        <b-button
          variant="outline-primary"
          class="mr-2"
          aria-label="ach button"
          @click="setSelectedPayableType('automatedClearingHouse')"
        >
          <b-img :src="`/images/automatedClearingHouses/ach.svg`" class="mr-2" />
          <span>
            ACH
          </span>
        </b-button>
      </div>
    </div>
    <validation-observer ref="observer" v-slot="{ invalid }">
      <b-form ref="form" @submit.prevent="savePaymentInfo">
        <template v-if="isTypeCard">
          <b-card no-body>
            <b-card-header
              header-tag="header"
              role="tab"
            >
              <b-button
                v-b-toggle.card-info
                variant="link"
                class="toggle-button"
                @click="toggleSection('card')"
              >
                <feather-icon
                  class="icon"
                  :type="isSectionOpen('card') ? 'chevron-down' : 'chevron-right'"
                />
                <span class="button-title">Card Information</span>
              </b-button>
            </b-card-header>
            <b-collapse
              id="card-info"
              v-model="isCardOpen"
              accordion="accordion"
              role="tabpanel"
            >
              <b-card-body>
                <p v-if="paymentFormDescription !== ''" class="text-center">
                  <em>{{ paymentFormDescription }}</em>
                </p>
                <div v-if="isTypeCard">
                  <credit-card-form
                    :edit-card="paymentMethodSelection"
                    @changed="paymentMethodInfoChanged"
                  />
                </div>
                <div v-if="isTypeACH">
                  <automated-clearing-house-form
                    :edit-ach="paymentMethodSelection"
                    @changed="paymentMethodInfoChanged"
                  />
                </div>
                <b-tooltip
                  v-if="invalid"
                  :target="`btn-submit`"
                  placement="top"
                  :triggers="'hover focus'"
                >
                  Please fill out the rest of the form.
                </b-tooltip>
              </b-card-body>
            </b-collapse>
          </b-card>

          <b-card no-body>
            <b-card-header
              header-tag="header"
              role="tab"
            >
              <b-button
                v-b-toggle.billing-info
                variant="link"
                class="toggle-button"
                @click="toggleSection('billing')"
              >
                <feather-icon
                  class="icon"
                  :type="isSectionOpen('billing') ? 'chevron-down' : 'chevron-right'"
                />
                <span class="button-title">Billing Information</span>
              </b-button>
            </b-card-header>
            <b-collapse
              id="billing-info"
              v-model="isBillingOpen"
              accordion="accordion"
              role="tabpanel"
            >
              <b-card-body class="billing-information-card">
                <address-form-v2
                  ref="addressForm"
                  :allow-existing-addresses="true"
                  :edit-address="selectedAddress"
                  :show-kind="false"
                  :is-adding-new-address="editMode || isAddingNewAddress"
                  :has-available-addresses="hasAvailableAddresses"
                  @changed="addressInfoChanged"
                  @close-new-address="toggleNewAddress(true)"
                  @reset-address-fields="resetAddress"
                />
              </b-card-body>
            </b-collapse>
          </b-card>

          <b-card v-if="hasAvailableAddresses && !isAddingNewAddress && !editMode" no-body>
            <div class="add-new-address-button">
              <b-button variant="link" @click="toggleNewAddress(false)">
                <feather-icon
                  class="icon"
                  :type="'plus-circle'"
                  :width="20"
                  :height="20"
                  :stroke="'white'"
                  :fill="'#009FBF'"
                />
                New Address
              </b-button>
            </div>
          </b-card>
        </template>
        <template v-else-if="isTypeACH">
          <automated-clearing-house-form
            :edit-ach="paymentMethodSelection"
            @changed="paymentMethodInfoChanged"
          />
        </template>
        <template>
          <b-card no-body>
            <div class="info-agreement-container">
              <payment-method-info-agreement />
            </div>
          </b-card>
        </template>

        <b-card-footer>
          <b-form-row>
            <div id="btn-submit">
              <button
                :disabled="!infoAgreement || isFormInvalid || addingPaymentMethod"
                class="btn btn-primary"
                type="submit"
              >
                {{ addingPaymentMethod ? 'Processing...' : submitMethodTitle }}
              </button>
            </div>
          </b-form-row>
          <b-form-row>
            <button
              class="btn btn-outline-primary"
              type="button"
              @click="closeModal"
            >
              Cancel
            </button>
          </b-form-row>
          <b-tooltip
            v-if="invalid"
            :target="`btn-submit`"
            placement="top"
            :triggers="'hover focus'"
          >
            Please fill out the rest of the form.
          </b-tooltip>
        </b-card-footer>
        <duplicate-payment-method-modal
          @cancelAddPaymentMethod="cancelAddPaymentMethod"
          @updatePreviousPaymentMethod="updatePreviousPaymentMethod"
          @close="cancelAddPaymentMethod"
        />
      </b-form>
    </validation-observer>
  </div>
</template>

<script>
import _ from 'lodash'
import { paymentMethodsMixin } from '@/mixins/paymentMethodsMixin'
import { ValidationObserver } from 'vee-validate'
import { mapActions, mapGetters } from 'vuex'
import TokenizerClient from '@/common/modules/tokenizer-client'

export default {
  name: 'PaymentMethodForm',
  components: {
    AddressFormV2: () => import('./shared/forms/AddressFormV2'),
    AutomatedClearingHouseForm: () => import('./shared/forms/AutomatedClearingHouseForm.vue'),
    CreditCardForm: () => import('./shared/forms/CreditCardForm'),
    CtCenteredSpinner: () => import('./shared/CtCenteredSpinner'),
    DuplicatePaymentMethodModal: () => import('@/components/DuplicatePaymentMethodModal'),
    FeatherIcon: () => import('@/components/shared/FeatherIcon'),
    PaymentMethodInfoAgreement: () => import('@/components/Payments/PaymentMethodInfoAgreement'),
    ValidationObserver,
  },
  mixins: [paymentMethodsMixin],
  props: {
    submitButtonText:{
      type: String,
      default: 'Add Payment Method',
    },
    updateButtonText:{
      type: String,
      default: 'Update Payment Method',
    },
    paymentFormDescription:{
      type: String,
      default: '',
    },
  },
  data() {
    return {
      paymentMethodInfo: {},
      addressInfo: {},
      errors: [],
      selectedAddress: null,
      tokenizerClient: new TokenizerClient(this.$store),
      isCardOpen: true,
      isBillingOpen: false,
      isAddingNewAddress: false,
      hasAvailableAddresses: false,
      activeSection: 'card',
      addingPaymentMethod: false,
    }
  },
  computed: {
    ...mapGetters(
      'account', ['account', 'addresses'],
    ),
    ...mapGetters(
      'website', ['website', 'isPurchaseCanaryWebsite']
    ),
    paymentMethodSelection() {
      return this.selectedPaymentMethod
    },
    editMode() {
      return !!this.selectedPaymentMethod
    },
    isShowTypeOptions() {
      return !this.editMode && !this.isPaymentMethodsPage && this.isPurchaseCanaryWebsite
    },
    isSectionOpen() {
      return (section) => this.activeSection === section
    },
    submitMethodTitle() {
      return !this.editMode ? this.submitButtonText : this.updateButtonText
    },
    hasInvalidBillingInfo() {
      if (!this.addressInfo || Object.keys(this.addressInfo).length === 0) return true

      const requiredFields = ['line1', 'city', 'state_province_region', 'zip_postal_code']
      return requiredFields.some(field => {
        const value = this.addressInfo[field]
        return value == null || value.trim() === ''
      })
    },
    hasInvalidPaymentMethodInfo() {
      if (!this.paymentMethodInfo || Object.keys(this.paymentMethodInfo).length === 0) return true
      if (this.editMode) return false

      return Object.values(this.paymentMethodInfo).some(
        value => value == null || (typeof(value) === 'string' && value.trim() === '')
      )
    },
    isFormInvalid() {
      if (!this.isTypeCard) return false
      if (this.isTypeCard && !this.$refs?.observer) return true

      const invalidForm = this.hasInvalidBillingInfo || this.hasInvalidPaymentMethodInfo
      return this.$refs?.observer?.invalid || invalidForm
    },
  },
  mounted() {
    this.checkAvailableAddresses()
    if (this.editMode && this.isTypeCard) {
      this.selectedAddress = this.tokenizerClient.addressFromTokenizer(this.selectedPaymentMethod.billing_address)
    }
  },
  methods: {
    ...mapActions('account', ['createPersonAddress']),
    paymentMethodInfoChanged (info) {
      this.paymentMethodInfo = info
      this.$nextTick(() => { this.$refs.observer?.validate() })
    },
    addressInfoChanged (info) {
      this.addressInfo = info
      this.validateForm()
    },
    async validateForm () {
      try {
        if (this.$refs.observer) await this.$refs.observer.validate()
      } catch (_error) { /* suppress error */ }
    },
    async savePaymentInfo () {
      this.addingPaymentMethod = true

      try {
        const valid = await this.$refs.observer.validate()
        if (!valid) return

        if (this.isTypeACH) this.paymentMethodInfo.agreement_indicator = true

        const tokenizerClient = new TokenizerClient(this.$store)

        if (this.isTypeCard) {
          // We formerly "prevented edit on card numbers to avoid exposing them". However, we are allowing it now.
          this.paymentMethodInfo.billing_address = tokenizerClient.addressToTokenizer(this.addressInfo)
        } else if (this.isTypeACH) {
          if (!this.paymentMethodInfo.agreement_indicator) {
            this.loadedPaymentMethods()
            return
          } else if (this.selectedPaymentMethod?.bank_account_number === this.paymentMethodInfo?.bank_account_number) {
            delete this.paymentMethodInfo?.bank_account_number
          }
        }

        const result = this.paymentMethodInfo.id
          ? await tokenizerClient.updatePaymentMethod(this.paymentMethodInfo, this.selectedPayableType)
          : await tokenizerClient.createPaymentMethod(this.paymentMethodInfo, this.selectedPayableType)

        await this.verifyResult(result)
      } catch (_error) {
        // suppress error
      } finally {
        this.addingPaymentMethod = false
      }
    },
    cancelAddPaymentMethod () {
      this.$bvModal.hide('payment-method-modal')
      this.loadedPaymentMethods()
    },
    async updatePreviousPaymentMethod () {
      const tokenizerClient = new TokenizerClient(this.$store)
      const result = await tokenizerClient.updatePaymentMethod(this.paymentMethodInfo, this.selectedPayableType)
      await this.verifyResult(result)
    },
    async verifyResult (result) {
      result.success ? await this.successfulResult(result) : await this.unSuccessfulResult(result)
    },
    async successfulResult (result) {
      await this.logEditExpiredCardInteraction(result.data)
      const addedPayableId = result.data?.id
      this.$emit('update', addedPayableId)
    },
    async unSuccessfulResult (result) {
      // after duplicate add attempt, keep form in scope and add the duplicate id to allow update with form values
      if (result.data?.message === 'Duplicate Payment Method') {
        this.paymentMethodInfo.id = result.data.duplicateId
        this.$bvModal.show('payment-method-duplicate-card-modal')
        return
      }

      if (_.isObjectLike(result.data) && result.data.errors) {
        this.errors = Object.keys(result.data.errors).map(key =>
          `${_.startCase(key)}: ${_.startCase(result.data.errors[key])}`
        )
      } else if (result.data.message === 'Bad card Issuer') {
        this.$emit('badCardIssuer')
        this.errors = "This card is not allowed by our processor. Please use a different card."
      } else if (result.data.message === 'Tokenizer Error') {
        this.errors = `There was an issue processing your ${this.printeablePaymentType}. Please try again later.`
      } else {
        const provider = this.isTypeCard ? 'card provider' : 'bank'
        this.errors = `The payment method you added has been declined. Please double check the ${this.printeablePaymentType} information or contact your ${provider}. You can also try another payment method.`
      }

      this.$emit('update', this.paymentMethodInfo?.id, this.errors)
    },
    async logEditExpiredCardInteraction (updatedPayable) {
      if (this.editMode && !updatedPayable.isExpired) {
        await this.logExpiredCardInteraction('edit', updatedPayable.id)
      }
    },
    checkAvailableAddresses () {
      this.hasAvailableAddresses = this.addresses.length > 0

      if (!this.hasAvailableAddresses) this.isAddingNewAddress = true
    },
    toggleNewAddress (forceClose = false) {
      this.isAddingNewAddress = forceClose ? false : !this.isAddingNewAddress

      if (this.isAddingNewAddress) {
        this.selectedAddress = null
        this.resetAddress()
        this.isBillingOpen = true
        this.isCardOpen = false
      }

      if (this.$refs.addressForm) {
        this.$refs.addressForm.resetAddressFields()
      }
    },
    resetAddress () {
      this.selectedAddress = null
      this.addressInfo = {}
      this.$emit("reset-address-fields")
    },
    toggleSection (section) {
      this.activeSection = this.activeSection === section ? null : section
    },
    closeModal () {
      this.$bvModal.hide('payment-method-modal')
    },
  },
}
</script>

<style scoped lang="scss">
// Mixins
@mixin flexbox($direction: row, $justify: center, $align: center) {
  display: flex;
  flex-direction: $direction;
  flex-wrap: nowrap;
  justify-content: $justify;
  align-items: $align;
}

@mixin title {
  color: #4E4E52;
  font-size: 1.0rem;
  font-weight: 400;
  text-decoration: none;
}

// Palette
$blue00: #009FBF;
$blue01: #2E3798;
$gray00: #D9D9D9;
$gray01: #CECED2;
$gray02: #363636;
$gray03: #4E4E52;
$gray04: #F1F1F1;
$gray05: #616165;
$gray06: #7D7D7D;

#payment-method-form-container {
  width: 100%;
  background: white;

  .choose-payment-header {
    @include flexbox(column, center, center);
    margin: 0 0 2.0rem 0;
    padding: 2rem 3rem 0 3rem;
    background-color: white;

    header {
      margin-bottom: 1.5rem;
      @include title();
    }

    .button-group {
      @include flexbox(row, space-around, center);
      flex-wrap: wrap;
      gap: 0.5rem;
    }

    .btn-outline-primary {
      @include flexbox(row, center, center);
      border-radius: 6px;
      border: 1px solid $gray00;
      background: white;
      color: $gray06;
      font-size: 0.75rem;
      font-style: normal;
      font-weight: 600;
      line-height: normal;

      &:hover, &:active {
        border-radius: 6px;
        border: 1px solid $gray06 !important;
        background: $gray04 !important;
        color: $gray02 !important;
      }
    }
  }

  .card {
    border: 0 !important;

    .card-header {
      padding: 0 1.0rem;
      border-bottom: 0;
      background: white;
      display: flex;
      align-items: center;

      .toggle-button {
        @include flexbox(row, center, center);
        padding-left: 0;

        &:hover {
          text-decoration: none;
        }

        .icon {
          color: $gray03;
        }

        .button-title {
          @include title();
        }

        .validation-icon {
          margin-left: 0.5rem;
          margin-bottom: 0.5rem;
        }
      }

      .title {
        @include title();
        padding-left: 1.25rem;
        margin-bottom: 0.5rem;
      }
    }

    .card-body {
      padding-top: 0;

      &.billing-information-card {
        padding-bottom: 0;
        padding-left: 0.5rem;
      }

      ::v-deep fieldset {
        legend {
          padding-left: 0.5rem;
          color: $gray05;
          font-size: 0.75rem;
          font-weight: 400;
        }

        input.form-control,
        select.form-control,
        select.custom-select {
          min-height: 2.5rem !important;
          border-radius: 5px;
          color: $gray05;
          font-size: 0.875rem !important;
          font-weight: 400;
        }
      }
    }

    .add-new-address-button {
      width: 100%;
      margin-bottom: 1.0rem;
      padding-left: 1.875rem;

      ::v-deep .btn-link {
        padding: 0.5rem 0;
        color: $blue00;
        font-size: 0.875rem;
        font-weight: 400;
        outline: 0;

        &:hover {
          text-decoration: none;
        }

        &:focus {
          outline: 0;
        }

        .icon {
          margin-right: 0;
        }
      }
    }

    .info-agreement-container {
      @include flexbox(row, flex-start, center);
      width: 100%;
      padding-left: 2.5rem;

      ::v-deep .agreement-container .row .agreement-text {
        label.custom-control-label {

          &::before {
            border-color: $gray01 !important;
            border-radius: 6px;
          }

          .custom-control-input:checked ~ label.custom-control-label {
            &::before {
              background: $blue00 !important;
            }

            &::after {
              background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3e%3cpath fill='%23FF0000' d='M6.564.75l-3.59 3.612-1.538-1.55L0 4.26 2.974 7.25 8 2.193z'/%3e%3c/svg%3e");
            }
          }

          span {
            font-size: 0.875rem;
            font-weight: 400;
          }

          a {
            font-size: 0.875rem;
            font-weight: 600;
            color: $blue00;
          }
        }
      }
    }
  }

  .card-footer {
    padding: 1.0rem !important;
    border-top: 1px solid #E1E1E5;
    background: white;

    .form-row {
      width: 100%;
      margin: 0 0 0.5rem 0 !important;

      #btn-submit {
        width: 100%;

        .btn-primary,
        .btn-outline-primary {
          width: 100%;
        }

        .btn-primary {
          width: 100%;
          background: $blue01;
          border-color: $blue01;
          font-size: 1.0rem;
          font-weight: 600;
        }
      }

      .btn-outline-primary {
        width: 100%;
        background: white;
        color: $gray03;
        border: 1px solid $gray01;
        border-radius: 5px;
        font-size: 1.0rem;
        font-weight: 600;

        &:hover {
          background: $blue00 !important;
          border-color: $blue00 !important;
        }
      }
    }
  }
}

@media only screen and (max-width: 576px) {
  #payment-method-form-container {
    .card {
      .card-body {

        &.billing-information-card {
          padding-left: 1.25rem;
        }

        .add-new-address-button {
          padding-left: 0;
        }
      }
    }

    ::v-deep .form-row {
      .col-6, .col-12 {
        padding-left: 0;
      }
    }
  }
}
</style>
